<template>
  <div class="page-gradient">
    <section class="title container center">
      <h3>Our Work</h3>
      <p>We're ready to give you the best product in the market.</p>
    </section>
    <div class="bottom-shape">
      <img src="~@/assets/images/page-shape.png" alt="" />
    </div>
  </div>
  <div class="portfolio section-space">
    <div class="container portfolio-wrapper">
      <div class="elfsight-app-b63a03ff-f8d0-459d-9fc0-33c3ec758e1a"></div>
    </div>
  </div>
</template>
